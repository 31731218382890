"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const useRedux_1 = require("@src/hooks/useRedux");
const react_helmet_1 = require("react-helmet");
const lodash_1 = require("lodash");
const jsonLd_1 = require("./jsonLd");
const MetaTags = ({ metaData, breadcrumbs, itemList }) => {
    const isArticle = 'published' in metaData;
    const socialMediaTitle = metaData?.ogTitle || metaData.title;
    const socialMediaDescription = metaData?.ogDescription || metaData.desc;
    const socialMediaImage = metaData?.ogImage || metaData.imageUrl;
    const canonicalUrl = metaData.canonicalUrl || window.location.href.split(/[?#]/)[0];
    const noindex = !!metaData?.noindex;
    const config = (0, useRedux_1.useAppSelector)(state => state.config.metadata);
    const jsonLd = [
        (0, jsonLd_1.getWebPageJsonLd)(canonicalUrl),
        ...(isArticle ? [(0, jsonLd_1.getArticleJsonLd)(metaData, config)] : []),
        ...(!(0, lodash_1.isEmpty)(breadcrumbs) ? [(0, jsonLd_1.getBreadcrumbJsonLd)(breadcrumbs || [])] : []),
        ...(!(0, lodash_1.isEmpty)(itemList) ? [(0, jsonLd_1.getItemListJsonLd)(itemList || [])] : [])
    ];
    (0, react_1.useEffect)(() => {
        if (metaData.desc) {
            const originalDescriptionElements = document.querySelectorAll('meta[name=description][data-initial="true"]');
            originalDescriptionElements.forEach(e => {
                e.parentNode?.removeChild(e);
            });
        }
    }, [metaData.desc]);
    return ((0, jsx_runtime_1.jsxs)(react_helmet_1.Helmet, { children: [metaData.title && (0, jsx_runtime_1.jsx)("title", { children: metaData.title }), metaData.desc && (0, jsx_runtime_1.jsx)("meta", { name: "description", content: metaData.desc }), metaData.keywords && ((0, jsx_runtime_1.jsx)("meta", { name: "keywords", content: metaData.keywords })), metaData.title && (0, jsx_runtime_1.jsx)("meta", { itemProp: "name", content: metaData.title }), metaData.desc && (0, jsx_runtime_1.jsx)("meta", { itemProp: "description", content: metaData.desc }), metaData.imageUrl && ((0, jsx_runtime_1.jsx)("meta", { itemProp: "image", content: metaData.imageUrl })), metaData.url && (0, jsx_runtime_1.jsx)("meta", { property: "og:url", content: metaData.url }), metaData.type && (0, jsx_runtime_1.jsx)("meta", { property: "og:type", content: metaData.type }), socialMediaTitle && ((0, jsx_runtime_1.jsx)("meta", { property: "og:title", content: socialMediaTitle })), socialMediaDescription && ((0, jsx_runtime_1.jsx)("meta", { property: "og:description", content: socialMediaDescription })), socialMediaImage && ((0, jsx_runtime_1.jsx)("meta", { property: "og:image", content: socialMediaImage })), (0, jsx_runtime_1.jsx)("meta", { name: "twitter:card", content: "summary_large_image" }), socialMediaTitle && ((0, jsx_runtime_1.jsx)("meta", { name: "twitter:title", content: socialMediaTitle })), socialMediaDescription && ((0, jsx_runtime_1.jsx)("meta", { name: "twitter:description", content: socialMediaDescription })), socialMediaImage && ((0, jsx_runtime_1.jsx)("meta", { name: "twitter:image", content: socialMediaImage })), !noindex && (0, jsx_runtime_1.jsx)("link", { rel: "canonical", href: canonicalUrl }), (0, jsx_runtime_1.jsx)("script", { type: "application/ld+json", children: JSON.stringify(jsonLd) }), noindex && (0, jsx_runtime_1.jsx)("meta", { name: "robots", content: "noindex, nofollow" })] }));
};
exports.default = MetaTags;
